<template>
  <div id="psi_pagecontainer_master" class="ppi_pageZone_container">
    <div class="ppi_pz_header noselect">
      <div class="pageZone_title">
        Sube tu Currículum Vítae 
      </div>
      <div class="pageZone_subtitle spacerRowTop24">
        Al compartir tu Currículum Vítae con nosotros podremos conocer a detalle tu perfil, experiencia laboral y 
        encontrar una vacante  ideal para ti.
      </div>
    </div>

    <ValidationObserver ref="formstepPiK" tag="div" class="ppi_contentZone spacerRowTop8" v-slot="{ handleSubmit, valid, passed }">
      <q-form 
        ref="myFormPik"
        @submit.prevent="handleSubmit(doSubmitData)"
        @reset="onStepFormPikReset"
        class="formDetailZone "
      >
        <!-- Validador general de la forma --> 
        <ValidationProvider 
          tag='div'
          :ref="'fldx_documentAdvance'" :immediate="true" name="documentAdvance"
          :rules=" {required: true, oneOf:[1, 2] } "
        >
          <input type="hidden" name="documentAdvance" v-model="canAdvance">
        </ValidationProvider>

          <div class="dataSection_container" v-if="zinitData">
            <div v-if=" ( Object.keys(curricDocumento).length>0 ) ? false : true" style="display: flex;">
              <div class="link_actionTextAlt action_item" @click.stop="do_canAdvance">
                Prefiero subir después mi Currículum Vítae
              </div>
            </div>

            <div class="spacerRowTop34"> <!-- spacerRowTop48  -->
              <!-- ZONA de item-curriculum -->
              <item-curriculum
                :key="catalogoCurric.id"
                :catalogo="catalogoCurric"
                :documento="curricDocumento[catalogoCurric.id + '']"
                @documento-updated="loadDocumentoCurriculum"
              >
              </item-curriculum>
            </div>

          </div>


        <div class="ppi_pz_footer spacerRowTop48">
          <div class="rowButtons">
            <div>
              <q-btn type="button" outline unelevated color="app_mainBtn" icon="r_chevron_left" label="Atrás" no-caps @click.stop="doPrevScreen"/> 
            </div>
            <div style="display: flex;flex-direction: column;">
              <q-btn type="submit" unelevated color="app_mainBtn" icon-right="r_chevron_right" label="Terminar" no-caps 
                :disable="((passed || valid) )? false : true"
              />
              <template v-if="qa_allowReviewLink===true">
                <div style="display: flex;justify-content: center;">
                  <div class="link_actionTextAlt action_item spacerRowTop16" @click.stop="goTo_PreviewPage">
                    Ir a mi resumen
                  </div>
                </div>
              </template>
            </div>

          </div>
        </div>

      </q-form>
    </ValidationObserver>

  </div>
</template>

<script>
import { requestPack } from './../../../../boot/axios' // APP code 
import logOut_Mixin from './../../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../../mixins/helperCatsMix.js'
import questionario_Mixin from './../../../../mixins/questionario/questionario_base.js'


import itemCurriculum from 'components/page-documents/item-curriculum.vue'


const tplpil_Data = {
  _tipo_catalogo_id: 772, // 764, 
  _hasRecord: false,
  _record:null,
  _uploading: false,
  _inputZoneId: 'quastionario_curriculum_Zone', 
  _inputId:'questionario_file_curric',
  _inputAgain_Id: 'questionario_file_curric_new',
  _uploadingProgress: 0,
  inputContents: null,
  fileErrors: {
    zonaFile: false,
    zonaFile_msg: ''
  },
  viewer_LinkUrl:'',
  viewer_Link: '', 
  viewer_LinkReady : false
}

export default {
  name: 'psi_screen_curriculum',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin,
    questionario_Mixin
  ],
  components:{
    //cxIcon: require('components/appIcons.vue').default,
    //pdfVisor: require('components/pdf-visor.vue').default,
    itemCurriculum
  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      zinitData: false,
      zinitScreen: false,
      zloadingData: false,
      processingData: false,
      //------------
      canAdvance: 0,
      //-------------
      catalogoDocs:[],
      catalogoCurric:{},
      curricDocumento:{},
      //-------------
      zflag_uploading: false,
      //-------------
      fxu_ConfigGeneral:{
        maxFiles: 1,
        maxFileSize: 5242880 , //5mb  // 31457280, //30mb  ---- //Size en bytes  //1mb = 1048576   //5mb 5242880
				fileFormats: "pdf,docx", // /(.*?)\.(jpg|jpeg|png|pdf)$/
				acceptStr: 'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      },
      docItem: JSON.parse(JSON.stringify(tplpil_Data)),
    }
  },
  computed: {
    hasDocumento (){ 
      if (this.catalogoDocs.length<=0){
        return false
      } else {
        if (this.catalogoDocs[0]===undefined || this.catalogoDocs[0]===null){
          return false
        } else { 
          return (this.curricDocumento[722]!==undefined) ? true:false 
        }
      }
    },
    current_userprofile (){
      return this.getProfileData
    },
  },
  watch: {
    is_Mobile: function( newValue, oldValue) { 
      if (newValue!== oldValue){ 

      }
    },
    pageSize : function( newValue, oldValue) { 
      if (newValue!== oldValue){ 

      }
    }
  },
  methods: {
    ...requestPack,
    test () { 
      this.$cannaDebug('-- psiPage_curric -- test')
    },
    showLoadx ( value, type ){ // muestra el componente Loading 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2  // type: 1 orbit -- 2 gears 
      this.$parent.showLoadr(tpuse , value )
    },
    doPrevScreen(){
      //this.$cannaDebug('-- psiPage_curric -- do Prev Screen --- ' + newRuta)
      // NOTA  DEv-sp 12  si si tiene experiencia  (ultimos empleos) de lo contrario  nos vamos a canales experiencia  
      var newRuta = ''
      var hasExperiencia = false
      try {
        var experieciaData = this.current_userprofile._embedded.userPerfilLaboralFiltro.filter(item=> item.categoriaId===14 && item.catalogo_id === 99)
        if (experieciaData.length>0) { //Si tiene experiencia  //Checamos los empleos (registros)
          hasExperiencia = true
        }
      } catch (ex ) { 
        this.$cannaDebug('-- psiPage_curric -- Error al checar experiencia --- ')
      }
      var newRuta = (hasExperiencia===true) ? '/dashboard/personal/aptitudes-c' : '/dashboard/personal/aptitudes-b'
      this.$router.push(newRuta)
    },
    do_canAdvance (){ 
      this.canAdvance = 1
      this.$forceUpdate()
      setTimeout(() => { this.doSubmitData() }, 400)
    },
    onStepFormPikReset () {

    },
    async doSubmitData () {
      this.$cannaDebug('-- psiPage_curric -- do Submit Data --- ' + newRuta)
      if (this.canAdvance <= 0){
        return false
      }
      if (!this.curricDocumento['772'] && this.canAdvance!==1){
        return false 
      }
      var newRuta = '/dashboard/personal/mediossociales'
      if ( this.curricDocumento['772']  &&  this.curricDocumento['772'].id ) { // Actualizar Datos de Estatus // WIP 
        var retUPS = null
        if (this.currProfile_status < this.zstatUser.CURRICULUM ){ // < 24
          await this.hpb_updateProfileStatus(this.zstatUser.CURRICULUM).then(respx=>{
            retUPS = respx
          })
        }
        // -----------
      }
      this.$router.push(newRuta)
    },
    async loadDocumentoCurriculum(){
      this.showLoadx(true, 2)
      await this.initFormData()
      this.showLoadx(false, 2)
    }, 
    async initFormData () {
      var test=11
      this.zinitData = false
      var mcModel = this.getModelRequest('profile_Docs')
      var reqRes = { action: [], login: [], profile: [] }
      var reqErr = { action: 0, login: 0, profile: 0 }
      //profile_Docs:['v2','profile/documento']  // ${secure}://${server}/index.php/api/v2/profile/documento?per-page=all&expand=catalogo&sort=-id&catalogo_id=764
      // Hasta aqui estamos bien y obtenemos los datos de documentos tipo curriculum 
      var myFormConfig = this.createAxiosConfig ('get', mcModel, {'per-page': 'all', expand:'catalogo', sort:'-id', catalogo_id:772}, true, null)
      await this.doRequestByPromise(this.$apiV2, 'profDocs', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Docs -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      //-----------------------
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        this.canAdvance = 0
        this.curricDocumento = {}
        this.zinitData = true
        return false 
      }
      await this._waitRequestProc(200)
      // -----------------------
      var myDocsData = reqRes.action[0].data.data
      if (myDocsData.length > 0){
        this.curricDocumento[myDocsData[0].catalogo_id] = JSON.parse(JSON.stringify(myDocsData[0])) 
        this.canAdvance = 1
        this.$forceUpdate()
      } else {
        this.curricDocumento = {} 
        this.canAdvance = 0
        this.$forceUpdate()
      }
      this.zinitData = true
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- psiPage_curric -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- psiPage_curric -- created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
  },
  beforeMount () {
    // this.$cannaDebug('-- psiPage_curric --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- psiPage_curric -- mounted')
    this.showLoadx(true, 2)
    if (this.$refs.fldx_documentAdvance){
      this.$refs.fldx_documentAdvance.reset()
    }

    if (this.getHelperCat(64).dataLoaded===false){ 
      await this.helpermix_getCatalog (64, null).then( respx => { 
        this.$cannaDebug('-- psiPage_curric --   categoria 64 docs personales ')
      })
    }

    await this._waitRequestProc(100)
    var catalogoCurric = this.getHelperCat(64).dataCatalog.filter(item => item.id==772) //Registro de curriculum 
    this.catalogoDocs = JSON.parse(JSON.stringify(catalogoCurric))
    this.catalogoCurric = (catalogoCurric.length>0) ? JSON.parse(JSON.stringify(catalogoCurric[0])) : {}

    await this._waitRequestProc(200)
    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    this.$forceUpdate()
    if (this.$refs.fldx_documentAdvance){
      this.$refs.fldx_documentAdvance.reset()
    }
    this.zloadingData = false
    this.showLoadx(false, 2)
    this.zinitScreen = true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- psiPage_curric -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- psiPage_curric -- updated')
  },
  async activated () {
    this.$cannaDebug('-- psiPage_curric -- activated')
    if (this.zinitScreen === true){ 
      this.zloadingData = true
    //   this.zinitData = false
    //   this.showLoadx(true, 1)

    var respInitData = null 
    await this.initFormData().then( respx => {
      respInitData = respx 
    })
    await this._waitRequestProc(200)

    //   this.zloadingData = false
    //   this.showLoadx(false, 1)

      this.zloadingData = false
    }
  },
  deactivated () {
    // this.$cannaDebug('-- psiPage_curric -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- psiPage_curric -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- psiPage_curric -- destroyed')
  }
}
</script>
